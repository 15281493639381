var config = {};
config.env = 'dev';

//AWS
config.aws = {};
//config.aws.apiRoot = "https://3d7qlpg9pd-vpce-0d5921de9d274d2eb.execute-api.us-west-2.amazonaws.com/dev/";
config.aws.apiRoot = "https://dev-api-mice.amgen.com/";
// config.aws.apiRoot = "https://dev-api-gims.amgen.com/";

//SSO
config.sso = {};
config.sso.url = "https://dev-630954.okta.com/home/selfdev630954_demookta_2/0oa1ihwzcwpC2mKfu357/aln1iiaocr7jdzWe1357";

//api-key
config.api = {};
config.api.key = 'RtGgbpLmas4gjFwB6w8oh7LlvN2Jxkdo5MFfj8Mn';

export default config;
